import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sushkin_contract_ended" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>28 Февраля палата по решению споров ФИФА приняла решение о справедливом расторжении контракта воспитанника питерского футбола Вячеслава Сушкина с ФК «Нефтчи Фергана» и обязала выплатить клуб финансовую компенсацию футболисту.</p>
          <p>18 июня в связи с невыполнением этого решения, ФИФА наложила бан на ФК «Нефтчи Фергана» сроком на три трансферных  окна. Таким образом, клуб в следующие три регистрационных периода получит возможность заявить новых футболистов только после того, как полностью рассчитается с Вячеславом Сушкиным.</p>
          <p>Делом футболиста в ФИФА занимается компания Catenaccio Sports Agency.</p>
        </div>
      </div>
    )
  }
}